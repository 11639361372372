
<template>
  <div>
    <v-navigation-drawer v-model="drawer" :clipped="clipped" fixed app>
      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="clipped" fixed app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <LanguageSwitcher />
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LanguageSwitcher from "../components/LanguageSwitcher";

export default {
  components: {
    LanguageSwitcher,
  },
  data() {
    return {
      clipped: true,
      drawer: true,
      fixed: true,
      items: [
        {
          icon: "mdi-apps",
          title: "Home",
          to: "/cabinet/home",
        },
        {
          icon: "mdi-calendar-month",
          title: "Calendar",
          to: "/cabinet/calendar",
        }, 
        {
          icon: "mdi-calendar-month",
          title: "Organization",
          to: "/cabinet/calendar",
        },
      ],
      miniVariant: false,
      title: "light booking",
    };
  },
};
</script>